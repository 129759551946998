<template>
  <div class="flex-1 flex overflow-hidden bg-gray-50">
    <main class="max-w-7xl flex-1 mx-auto w-full pb-6 lg:py-6 lg:px-6 2xl:order-last">
      <div class="flex flex-col lg:grid lg:grid-cols-12 lg:gap-x-5 h-full">
        <aside class="py-6 sm:px-6 lg:py-0 lg:px-0 lg:col-span-2">
          <nav class="space-y-1">
            <slot name="sideNavigation"></slot>
          </nav>
        </aside>

        <div class="flex flex-col space-y-6 sm:px-6 lg:px-0 lg:col-span-10 h-full overflow-auto">
          <slot name="sections"></slot>
        </div>
      </div>
    </main>
    <slot name="side-list"></slot>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "DetailsPage",
  components: {},
  setup() {
    return {};
  }
});
</script>
