
import { defineComponent, computed } from "vue";
import { useRoute } from "vue-router";

export default defineComponent({
  name: "DetailsLink",
  props: {
    name: {
      type: String,
      required: true
    },
    link: {
      type: String,
      required: true
    },
    routeNameList: {
      type: Array
    },
    routePage: {
      type: String
    }
  },
  setup(props) {
    const route = useRoute();
    // Create status for css classes
    // Links if active, if matches route name or route page
    const linkStatus = computed(() => {
      if (props.routePage != undefined) {
        if (route.meta.page == props.routePage) {
          return "active";
        } else {
          return "inactive";
        }
      } else if (props.routeNameList != undefined) {
        if (props.routeNameList.includes(route.name)) {
          return "active";
        } else {
          return "inactive";
        }
      }
    });

    // CSS classes for icon
    const iconClasses = computed(() => {
      return {
        active: "text-blue-500 group-hover:text-blue-500",
        inactive: "text-gray-400 group-hover:text-gray-500"
      }[linkStatus.value as "active" | "inactive"];
    });
    // CSS classes for text
    const linkClasses = computed(() => {
      return {
        active: "bg-gray-50 text-blue-500 hover:bg-white",
        inactive: "text-gray-900 hover:text-gray-900 hover:bg-gray-100"
      }[linkStatus.value as "active" | "inactive"];
    });
    return { iconClasses, linkClasses };
  }
});
