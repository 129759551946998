
import { defineComponent } from "vue";

export default defineComponent({
  name: "DetailsPage",
  components: {},
  setup() {
    return {};
  }
});
