<template>
  <aside class="hidden 2xl:order-first 2xl:flex 2xl:flex-col flex-shrink-0 w-72 border-r border-gray-200">
    <div class="px-6 pt-6 pb-4">
      <h2 class="text-lg font-medium text-gray-900">{{ header }}</h2>
      <p class="mt-1 text-sm text-gray-600">
        {{ caption }}
      </p>
      <form class="mt-6 flex space-x-4">
        <SearchBox />
        <!-- 
        <BaseButton>
          <svg
            class="h-5 w-5 text-gray-400"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            aria-hidden="true"
          >
            <path
              fill-rule="evenodd"
              d="M3 3a1 1 0 011-1h12a1 1 0 011 1v3a1 1 0 01-.293.707L12 11.414V15a1 1 0 01-.293.707l-2 2A1 1 0 018 17v-5.586L3.293 6.707A1 1 0 013 6V3z"
              clip-rule="evenodd"
            />
          </svg>
        </BaseButton> -->
      </form>
    </div>
    <!-- Item list -->
    <nav class="flex-1 min-h-0 relative overflow-y-auto">
      <slot name="items"></slot>
    </nav>
  </aside>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import SearchBox from "@/components/SearchBox.vue";

export default defineComponent({
  name: "DetailsSideList",
  components: {
    SearchBox
  },
  props: {
    header: {
      type: String,
      required: true
    },
    caption: {
      type: String
    }
  },
  setup() {
    return {};
  }
});
</script>
