
import { defineComponent } from "vue";
import SearchBox from "@/components/SearchBox.vue";

export default defineComponent({
  name: "DetailsSideList",
  components: {
    SearchBox
  },
  props: {
    header: {
      type: String,
      required: true
    },
    caption: {
      type: String
    }
  },
  setup() {
    return {};
  }
});
